<template>
  <v-main>
    <v-container>
      <v-row justify="center">
        <v-col style="text-align: center">
          <h2>Ви знаєте, що робити 👇</h2>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="4" style="margin-top: 20px">
          <v-form>
            <v-text-field
                v-model="form.username"
                label="Логін"
                required
                clearable
                solo
                @keyup.enter="openMain"
            >
            </v-text-field>
            <v-text-field
                v-model="form.password"
                :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPwd ? 'text' : 'password'"
                label="Гасло"
                required
                solo
                @click:append="showPwd = !showPwd"
                @keyup.enter="openMain"
            >
            </v-text-field>
            <div style="text-align: center; color: white">
              <v-btn
                  block
                  :loading="loading"
                  :disabled="form.password.length < 2 || form.username.length < 2"
                  @click="openMain"
                  color="success"
                  large
              >
                Увійти
              </v-btn>
            </div>
          </v-form>
          <p v-if="showError" style="color: red; margin-top: 20px">{{ errMsg }}</p>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import {mapActions} from "vuex";


export default {
  name: "Login",
  components: {},
  data() {
    return {
      errMsg: '',
      loading: false,
      showPwd: false,
      form: {
        username: "",
        password: "",
      },
      showError: false
    };
  },
  methods: {

    openMain() {
      this.loading = true;
      this.submit()


    },
    ...mapActions(["LogIn"]),
    async submit() {
      const User = {"username": this.form.username, "password": this.form.password}
      try {
        await this.LogIn(User);
        if(this.$store.getters.checkUserRole === 'SPAMMER'){
          await this.$router.push("/spam");
        }else{
          await this.$router.push("/mainPage");
        }

        this.showError = false
      } catch (error) {
        console.log('errr -> ', error)
        this.loading = false;
        if(typeof error === 'object' ){
          this.errMsg = error.message;
        }else {
          this.errMsg = error;
        }

        this.showError = true
      }
    },
  },
};
</script>

