<template>

  <v-dialog
      v-model="deviceSettingDialog"
      persistent
      max-width="600px"
      :retain-focus="false"
  >
    <change-kiosk-background v-if="isKioskBgEditorVisible" :device-id="device.deviceId" :firebase-token="currentDevice.firebaseToken"
                             :is-dialog-visible="isKioskBgEditorVisible"
                             @close-changer="toggleShowEditKioskBg"></change-kiosk-background>
    <v-snackbar
        v-model="snackbar"
        color="warning"
        absolute
        top
        right
        elevation="24"
        centered
        text
        timeout="3500">
      Назва касира відсутня
      <template v-slot:action="{ attrs }">
        <v-btn
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card>
      <v-progress-linear v-if="loading"
                         :active="loading"
                         :indeterminate="loading"
                         height="6px"
      ></v-progress-linear>
      <v-card-title>
        <div class=" mb-lg-7">
          <div v-if="device.type">
            <v-avatar v-if="device.type.toUpperCase() === 'KIOSK'"
                      tile
                      size="25"
            >
              <img
                  :src="require(`../../assets/kiosk_${device.isActive ? 'active' : 'noactive'}.png`)"
                  alt="kiosk"

              >
            </v-avatar>
            <v-icon v-else
                    :color="device.isActive ? 'success' : 'pink'"
                    dark
            >
              mdi-cellphone
            </v-icon>
          </div>
          <span class="text-overline">Девайс id:</span> <span class="text--secondary">{{ currentDevice.deviceId }}</span>
          <v-btn icon v-if="currentDevice.firebaseToken">
            <v-icon color="warning" @click="isFirebaseTokenVisible = !isFirebaseTokenVisible">mdi-firebase</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <transition name="bounce">
        <v-card-subtitle v-if="isFirebaseTokenVisible">
          <v-divider></v-divider>
          <copy-to-clipboard-component hint-text="firebase token" :value="currentDevice.firebaseToken" hint-direction="top"></copy-to-clipboard-component>
          <small>{{ currentDevice.firebaseToken }}</small>
          <v-divider></v-divider>
        </v-card-subtitle>
      </transition>
      <v-card-text>
        <expansion-actions-wrapper-component v-if="currentDevice.firebaseToken" :type="currentDevice.type" :firebase-token="currentDevice.firebaseToken" :is-active="currentDevice.isActive" :device-id="currentDevice.deviceId"></expansion-actions-wrapper-component>
        <v-container>
          <v-row>

            <v-col
                cols="12"
                sm="12"
            >
              {{ currentDevice.locationName }}
              <v-autocomplete
                  :items="avalibleData.avalibleLocations"
                  :item-text="'locationName'"
                  :item-value="'locationId'"
                  label="Локація*"
                  required
                  v-model="currentDevice.locationId"
                  :disabled="loading"
              ></v-autocomplete>

              <span style="margin-top: 20px">{{ currentDevice.cashiers }}</span>
              <v-autocomplete
                  style="margin-top: 5px"
                  :items="avalibleData.avalibleCashiers"
                  :item-text="'cashierName'"
                  :item-value="'cashierId'"
                  label="Касири*"
                  required
                  v-model="currentDevice.cashiersId"
                  chips
                  multiple
                  :disabled="loading"
              ></v-autocomplete>
            </v-col>
            <v-col
                cols="12"
                sm="12"
            >
              <v-text-field
                  v-model="currentDevice.comment"
                  label="Коментар"
                  :disabled="loading"
              ></v-text-field>
              <v-row>

                <v-switch
                    v-model="currentDevice.isActive"
                    :label="`${currentDevice.isActive ? 'активний' : 'неактивний'}`"
                    :disabled="loading"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="mr-4"
                        v-bind="attrs"
                        v-on="on"
                        @click="generateQR"
                    >
                      <v-icon>
                        {{!isNeedQr && !isNeedBarCode ? 'mdi-qrcode' : (!isNeedQr ? 'mdi-qrcode' : 'mdi-barcode')}}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Генерувати {{!isNeedQr && !isNeedBarCode ? 'QR' : (!isNeedQr ? 'QR' : 'Штрих')}}</span>
                </v-tooltip>
                <div v-if="device.type">
                  <v-tooltip bottom v-if="device.type.toUpperCase() === 'KIOSK'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          class="mr-4"
                          v-bind="attrs"
                          v-on="on"
                          @click="toggleShowEditKioskBg"
                      >
                        <v-icon>
                          mdi-file-image-plus-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Змінити Заставку</span>
                  </v-tooltip>
                </div>
              </v-row>

            </v-col>
          </v-row>
          <div v-if="isNeedQr" style="text-align: center;padding: 20px;">
            <qrcode-vue :value="currentDevice.deviceId" size="150" level="H"/>
          </div>
          <div v-if="isNeedBarCode" style="text-align: center !important; padding: 20px 10px 20px 80px;">
            <v-img style="width: 80%" contain
                   :src="`https://barcodeapi.org/api/auto/${currentDevice.deviceId}`">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="55"
                      color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
          <small v-if="isNeedQr || isNeedBarCode">*{{ isNeedQr ? 'Qr' : 'Штрих' }} код для сканування на
            девайсі!</small>
        </v-container>
        <small>*Всі поля є обов'язковими!</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="closeModal"
            :disabled="loading"
        >
          Відміна
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="updateDeviceData"
            :disabled="loading"

        >
          Зберегти
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiService from "@/apiServices/apiService";
import QrcodeVue from 'qrcode.vue';
import ChangeKioskBackground from "@/components/ChangeKioskBackground.vue";
import {copyToClipboard} from "@/utils/copy";
import CopyToClipboardComponent from "@/components/CopyToClipboardComponent.vue";
import ExpansionActionsWrapperComponent from "@/components/FireBaseActions/ExpansionActionsWrapperComponent.vue";

export default {
  name: "DeviceSetting",
  components: {
    ExpansionActionsWrapperComponent,
    CopyToClipboardComponent, ChangeKioskBackground, QrcodeVue},
  props: {
    value: Boolean,
    device: Object,
    locationsAndCashiersData: Object,
  },
  data: () => ({
    isFirebaseTokenVisible: false,
    isNeedQr: false,
    isNeedBarCode: false,
    snackbar: false,
    loading: false,
    success: false,
    selectedLocation: '',
    selectedCashiers: [],
    avalibleData: {},
    isKioskBgEditorVisible: false


  }),
  methods: {
    copyToClipboard,

    toggleShowEditKioskBg() {
      console.log('receive action to toggle show/hide doalog')
      this.isKioskBgEditorVisible = !this.isKioskBgEditorVisible
    },

    generateQR() {
      console.log('gen qr')
      if (this.device.deviceId.length > 3) {
        if (!this.isNeedQr && !this.isNeedBarCode) {
          this.isNeedBarCode = true
        }
        if (this.isNeedQr) {
          this.isNeedQr = false
          this.isNeedBarCode = true
        } else {
          if (this.isNeedBarCode ) {
            this.isNeedQr = true
            this.isNeedBarCode = false
          }
        }


        console.log('QR: ', this.isNeedQr, 'Bar: ', this.isNeedBarCode)
      } else {
        this.snackbar = true;
      }

    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },

    closeModal() {
      this.deviceSettingDialog = false;
    },
    async updateDeviceData() {
      const {locationName, cashiers, ...newDeviceData} = this.currentDevice;
      console.log(newDeviceData, locationName, cashiers)
      this.loading = true;
      try {
        const updateResponse = await apiService.editDevice(newDeviceData);
        if (updateResponse.success) {
          this.loading = false;
          this.$store.commit('setSnackMessage', {
            message: `Дані оновлено успішно 👍`,
            isError: false
          })
          this.deviceSettingDialog = false;
        } else {
          this.loading = false;
          this.$store.commit('setSnackMessage', {
            message: `❕Халепа \n${updateResponse.error}`,
            isError: true
          })
          // alert(`Халепа ❗️\n\n${updateResponse.error}`)
        }
      } catch (e) {
        this.$store.commit('setSnackMessage', {
          message: `❕${e}`,
          isError: true
        })
        this.loading = false;
      }


    },
    async getAllAvalibleData() {
      try {

        const avalibleLocations = await apiService.getLocations();
        if ('msg' in avalibleLocations) return false;
        const avalibleCashiers = await apiService.getCashiers();
        if ('msg' in avalibleCashiers) return false;

        this.avalibleData.avalibleLocations = avalibleLocations.locations;
        this.avalibleData.avalibleCashiers = avalibleCashiers.cashiers;


      } catch (e) {
        this.$store.commit('setSnackMessage', {
          message: `❕ Халепа отримання даних \nпо локаціях та касирах \nдля створення/редагування девайсів️ \n\n${e}`,
          isError: true
        })

      }

    },
  },

  mounted() {
    this.getAllAvalibleData();
  },
  computed: {
    deviceSettingDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    currentDevice: {
      get() {
        console.log('current device -> ', this.device)
        return this.device
      },
    },

  },
  watch: {
    deviceSettingDialog(val) {
      console.log('is Dialog open ---> ', val);
      console.log('Get avalibleData in device settings -> ', this.avalibleData)


    },
  }
}
</script>

<style scoped>

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

</style>
