import axios from 'axios';
import store from '@/store'
import router from "@/router";
import urls from "@/apiServices/urls";
const authService = axios.create({
    baseURL: urls.baseURL,
    withCredentials: true,
    xsrfCookieName: 'csrf_access_token',
    xsrfHeaderName: 'csrf_refresh_token'
});
authService.defaults.headers.common['Content-Type'] = 'application/json, application/x-www-form-urlencoded, multipart/form-data';

const COOKIE_EXPIRED_MSG = 'Token has expired'
authService.interceptors.response.use((response) => {
    return response
}, async (error) => {
    console.log(error.response)
    let error_message;
    switch (error.response.status) {
        case 401:
            error_message = error.response.data.msg;
            if(error.response.request.responseURL.includes('/refresh') || error_message.includes('Missing cookie')){
                console.log('req url -> ',error_message, error.response.request.responseURL, error.response.request.responseURL.includes('/refresh'));
                await store.dispatch("LogOut");
                await router.push('/');

            }else{
                if (!error.config.retry && error_message === COOKIE_EXPIRED_MSG) {
                    error.config.retry = true
                    authService.defaults.xsrfCookieName = 'csrf_refresh_token';
                    await authService.post('/refresh')
                    authService.defaults.xsrfCookieName = 'csrf_access_token';
                    return authService(error.config)
                }
            }

            break;
        case 404:
            throw '--<=== requested API URL not found ===>--';

        default:
            throw ` API request error> ${error.response.status} : ${error.response.statusText}`
    }
    return error.response;
});
export { authService };
