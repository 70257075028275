import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router';
import store from './store';
import VueExcelXlsx from "vue-excel-xlsx";

Vue.prototype.$gSnackbarMsg = []
Vue.use(VueAxios, axios, router);
Vue.use(VueExcelXlsx);

Vue.config.productionTip = false;


new Vue({
  store,
  router: router,
  vuetify,
  render: h => h(App)
}).$mount('#app')



