const state = {
    shiftTimeStart: '07:00:00', shiftTimeEnd: '06:59:00'
};

const getters = {
    getShiftTimeStart: state => state.shiftTimeStart,
    getShiftTimeEnd: state => state.shiftTimeEnd,
};

const actions = {}

const mutations = {
    changeShiftTimeStart (state, data){
        state.shiftTimeStart = data;
    },
    changeShiftTimeEnd (state, data){
        state.shiftTimeEnd = data;
    },
    changeShiftTime (state, {shiftTimeStart, shiftTimeEnd}){
        state.shiftTimeStart = shiftTimeStart;
        state.shiftTimeEnd = shiftTimeEnd;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};
