import { render, staticRenderFns } from "./CreateNewCashier.vue?vue&type=template&id=6493d228&scoped=true&"
import script from "./CreateNewCashier.vue?vue&type=script&lang=js&"
export * from "./CreateNewCashier.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6493d228",
  null
  
)

export default component.exports