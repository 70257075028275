<template>
  <v-main>
    <div style="text-align: center; margin: 10%">
      <h1>Немає такого!!!</h1>
    </div>
  </v-main>

</template>

<script>
export default {
name: "PageNotFound"
}
</script>

<style scoped>

</style>
