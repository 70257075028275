<template>
  <v-menu
      v-model="quickActionMenu"
      :close-on-click="false"
      :close-on-content-click="false"
      bottom
      left
      offset-y
      offset-x
      rounded="lg"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          dark
          color="red"
          icon
          v-bind="attrs"
          v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <device-actions-component is-list-component :device="device" @onClickAndFinish="closeMenu"></device-actions-component>
    </v-list>
  </v-menu>
</template>

<script>
import DeviceActionsComponent from "@/components/FireBaseActions/DeviceActionsComponent.vue";

export default {
  name: "DeviceQuickAction",
  components: {DeviceActionsComponent},
  props: {
    device : {
      type: Object,
      required: true,
      default: function () {
        return {isActive: false, deviceType: ''}
      }
    }
  },
  data() {
    return {
      quickActionMenu: false
    }
  },
  methods: {
    closeMenu() {
      this.quickActionMenu = false
    }
  }
}
</script>

<style scoped>

</style>