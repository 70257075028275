const state = {
    uploadApkProgressVal: 0
};

const getters = {
    getProgressVal: state =>{
        return state.uploadApkProgressVal
    }
};

const actions = {}

const mutations = {
    updateProgressVal (state, data){
        state.uploadApkProgressVal = data;
    }

};

export default {
    state,
    getters,
    actions,
    mutations,
};
