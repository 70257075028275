<template>
  <v-hover v-slot="{ hover }">
    <v-card
        :elevation="hover ? 8 : 3"
        class="mx-auto"

    >
      <v-card-text>
        <div>Підсумок</div>
        <v-divider></v-divider>

        <p style="margin-top: 15px">Загальна сума: </p>
        <p class="text-h4 text--primary">
          {{ summary.totalAmount.toLocaleString('uk-UA') }}{{ amountType }}.
        </p>
        <p class="text-h6">Кількість транзакцій: {{ summary.totalOperations }}</p>
        <p class="text-h8">Поповнень: {{ summary.totalDepositsAmount.toLocaleString('uk-UA') }}{{amountType}}.
          ({{ summary.totalDepositsCounter }})</p>
        <p class="text-h8">Виплат: {{ summary.totalWithdrawAmount.toLocaleString('uk-UA') }}{{amountType}}.
          ({{ summary.totalWithdrawCounter }})</p>
      </v-card-text>
      <v-card-actions>
        <vue-excel-xlsx
            :data="xlsData"
            :columns="xlsColumns"
            :file-name="fileName"
            :file-type="'xlsx'"
            :sheet-name="'sheet'"

        >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="green"
                  icon
                  variant="text"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn>
            </template>
            <span>Завантажити .xlsx</span>
          </v-tooltip>
        </vue-excel-xlsx>
        <v-spacer></v-spacer>
        <!--        <v-switch-->
        <!--            v-model="chartToggle"-->
        <!--            inset-->
        <!--            :label="`Графік`"-->
        <!--        ></v-switch>-->
      </v-card-actions>


    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "TransactionSummaryCard",
  props: {
    type: {
      type: String,
      default: 'points'
    },
    dateRange: Array,
    summary: {
      type: Object,
      required: true,
      default: () => {
        return {
          totalAmount: 0,
          totalOperations: 0,
          totalDepositsAmount: 0,
          totalDepositsCounter: 0,
          totalWithdrawAmount: 0,
          totalWithdrawCounter: 0
        }

      },
    },
    xls: {
      type: Object,
      required: true,
      default: () => {
        return {
          data: [],
          column: []
        }
      }
    }
  },
  computed: {

    amountType() {
      return this.type === 'points' ? 'p' : 'tk'
    },

    fileName() {
      if(this.dateRange){
        return `${this.type}_transactions_${new Date(this.dateRange[0]).toLocaleDateString('uk-UA')}-${new Date(this.dateRange[1]).toLocaleDateString('uk-UA')}`
      }else{
        return `${this.type}_transactions_${new Date().toLocaleDateString('uk-UA')}`
      }

    },

    xlsColumns() {
      return this.xls.column.map((col) => {
        return {
          label: col['text'],
          field: col['value'],
        }
      })
    },
    xlsData() {
      const clonedData = []
       this.xls.data.forEach(row => {
          const clonedRow = {...row}
          clonedRow['timestamp'] = new Date(row['timestamp']).toLocaleString('uk-UA')
          clonedData.push(clonedRow)
        })
      return clonedData
    }
  }
}
</script>


<style scoped>

</style>