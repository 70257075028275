<template>
  <v-dialog
      v-model="cashierSettingDialog"
      persistent
      max-width="600px"
      :retain-focus="false"
  >

    <v-card>
      <v-progress-linear v-if="loading"
                         :active="loading"
                         :indeterminate="loading"
                         height="6px"
      ></v-progress-linear>
      <v-card-title>
        <span class="text-overline mb-lg-7">Касир <span style="color: #3a983a">{{currentCashier.cashierName}}</span> id: {{ currentCashier.cashierId }} </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>

            <v-col
                cols="12"
                sm="12"
            >
              {{currentCashier.locationName}}
              <v-autocomplete
                  :items="avalibleData.avalibleLocations"
                  :item-text="'locationName'"
                  :item-value="'locationId'"
                  label="Локація*"
                  required
                  v-model="currentCashier.locationId"
                  :disabled="loading"
              ></v-autocomplete>
            </v-col>
            <v-col
                cols="12"
                sm="12"
            >
              <v-text-field
                  v-model="currentCashier.comment"
                  label="Коментар"
                  :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="closeModal"
            :disabled="loading"
        >
          Відміна
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="updateCashierData"
            :disabled="loading"

        >
          Зберегти
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiService from "@/apiServices/apiService";

export default {
name: "ChangeCashierDialog",

  props: {
    value: Boolean,
    cashier: Object,
  },
  data: () => ({
    snackbar: false,
    loading: false,
    success: false,
    selectedLocation: '',
    avalibleData: {},


  }),
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },

    closeModal() {
      this.cashierSettingDialog = false;
    },
    async updateCashierData(){
      console.log('new cashier data -> ', this.currentCashier);
      this.currentCashier['id'] = this.currentCashier['cashierId'];
      this.loading = true;
      try {
        const updateResponse = await apiService.editCashiers(this.currentCashier);
        if(updateResponse.success){
          this.loading = false;
          this.$store.commit('setSnackMessage', {
            message: 'Дані оновлено успішно 👍',
            icon: 'mdi-check',
          })
          this.deviceSettingDialog = false;
        }else {
          this.$store.commit('setSnackMessage', {
            message: `Халепа ️\n\n${updateResponse.error}`,
            isError: true
          })
          this.loading = false;
        }
      }catch (e) {
        this.$store.commit('setSnackMessage', {
          message: `${e}`,
          isError: true
        })
        this.loading = false;
      }


    },
    async getAllAvalibleData(){
      try {

        const avalibleLocations = await apiService.getLocations();
        if('msg' in avalibleLocations) return false;
        this.avalibleData.avalibleLocations = avalibleLocations.locations;



      }catch (e) {
        this.$store.commit('setSnackMessage', {
          message: `Халепа отримання даних\n по локаціях️ \n\n${e}`,
          isError: true
        })
      }

    },
  },

  mounted() {
    this.getAllAvalibleData();
  },
  computed: {
    cashierSettingDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    currentCashier: {
      get() {
        console.log('current device -> ', this.cashier)
        return this.cashier
      },
    },

  },
  watch: {
    deviceSettingDialog(val){
      console.log('is Dialog open ---> ', val);
      console.log('Get avalibleData in device settings -> ',this.avalibleData)


    },
  }
}
</script>

<style scoped>

</style>
