<template>
  <v-app>
    <NavBar />
<!--    <div style="z-index: 999999; background: #ba68c8 !important;">-->
<!--      <h1>ola: {{$store.state.snackMessage.snackMessage.length}} {{$store.state.snackMessage.snackMessage}}</h1>-->
<!--    </div>-->
    <v-snackbars v-if="$store.state.snackMessage.snackMessage"
        :objects.sync="$store.state.snackMessage.snackMessage"
        elevation="24"
        top
        right
    >
      <template v-slot:action="{ close, message }">
        <div
            style="width: 76px"
            class="text-center"
            v-if="message.includes('...')"
        >
          <v-progress-circular
              indeterminate
              :width="2"
              :size="25"
          ></v-progress-circular>
        </div>
        <v-btn text @click="close()" v-else>Ok</v-btn>
      </template>
      <template v-slot:default="{ message }">
        <v-icon v-if="message.includes('_mdi-icon=')">
          {{message.split('_mdi-icon=')[1]}}
        </v-icon>
        {{ message.includes('_mdi-icon=') ? message.split('_mdi-icon=')[0] : message  }}
      </template>
    </v-snackbars>
    <router-view/>

  </v-app>


</template>
<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import VSnackbars from "v-snackbars";
export default {
  components: {
    NavBar,
    "v-snackbars": VSnackbars,
  },
  created() {
    console.log('========== created ==========')
  }

}
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  opacity: 1;
  -webkit-text-fill-color:;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
