<template>
  <v-tooltip :top="hintPosition.top" :bottom="hintPosition.bottom" :right="hintPosition.right" :left="hintPosition.left"  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon @click="cp(value)">
        <v-icon size="20" color="primary">mdi-content-copy</v-icon>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>
<script>
import {copyToClipboard} from "@/utils/copy";

export default {
  name: "CopyToClipboardComponent",
  props: {
    hintText: {
      type: String,
      default: ''
    },
    hintDirection: {
      type: String,
      default: 'top',
      validator: function (position) {
        return ['top', 'bottom', 'right', 'left'].includes(position)
      }
    },
    value : String
  },
  methods: {
    async cp (){
      if (this.value){
        await copyToClipboard(this.value)
        this.$gSnackbarMsg.push({message: `${this.hintText} скопійовано у буфер`})
      }

    }
  },
  computed: {
    text : function () {
      return `копіювати ${this.hintText}`
    },
    hintPosition: function () {
      const positions = {
        top: false,
        bottom: false,
        right: false,
        left: false
      }
      positions[this.hintDirection] = true
      return positions
    }
  },
}
</script>
<style scoped>

</style>