import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import updater from "@/store/modules/updater";
import uploadProgress from "@/store/modules/uploadProgress";
import settings from "@/store/modules/settings";
import snackMessage from "@/store/modules/snackMessage";


// Load Vuex
Vue.use(Vuex);
// Create store
export default new Vuex.Store({
    modules: {
        auth,
        updater,
        uploadProgress,
        settings,
        snackMessage
    },
    plugins: [createPersistedState({
        paths: ['auth', 'settings']
    })]
});
