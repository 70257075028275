<template>
  <v-main>
    <v-container fluid class=" lighten-5">
      <v-snackbar
          v-model="snackbar.isVisible"
          :timeout="4000"
          right
          top
          color="black"
      >
        {{ snackbar.text }}

        <template v-slot:action="{ attrs }">
          <v-progress-circular :value="20" indeterminate v-bind="attrs"></v-progress-circular>
        </template>
      </v-snackbar>
      <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          height="4px"
      ></v-progress-linear>
      <v-tabs>
        <v-tab>UAH</v-tab>
        <v-tab>Points</v-tab>
        <v-tab>Tickets</v-tab>
        <v-tab-item>
          <v-row class="pa-10">
            <v-col
                md="3"
            >
              <div class="pa-1" v-if="hideSelect">
                <v-card>
                  <v-card-text>
                    <p>
                      <v-icon color="primary">mdi-hospital-building</v-icon>
                      <span style="margin-left: 10px"><b>{{ selectedLocationName }}</b></span>
                    </p>

                    <div>
                <span v-for="(cashierName, index) in selectedCashiersNames" :key="index">
                  <v-icon color="success">mdi-account-circle</v-icon>
                   {{ cashierName }}
                </span>
                    </div>

                  </v-card-text>
                  <v-card-actions>
                    <span>{{ selectedDate.map(dt => formatDate(dt)).join(' - ') }}</span>
                    <v-spacer></v-spacer>
                    <div style="padding-bottom: -10px; ">

                      <v-btn
                          icon
                          @click="hideSelect = !hideSelect"
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </div>
                  </v-card-actions>

                </v-card>
              </div>
              <div v-if="!hideSelect">
                <v-select
                    v-model="selectedLocation"
                    :items="locationsWithCashiers"
                    :item-text="'locationName'"
                    :item-value="'locationId'"
                    label="Оберіть зал"
                    prepend-icon="mdi-map"
                    @change="selectVenueHandler"

                >


                </v-select>

                <v-select
                    v-model="selectedCashier"
                    :items="cashiersList"
                    :item-text="'cashier_name'"
                    :item-value="'cashier_id'"
                    :disabled="cashiersList.length < 1"
                    label="Оберіть касира"
                    hint="Можна обрати кілька"
                    multiple
                    chips
                    prepend-icon="mdi-account"
                    @change="selectCashiersHandler"
                >

                </v-select>

                <v-menu
                    ref="menu"
                    v-model="datePickerMenu"
                    :close-on-content-click="false"
                    :return-value.sync="selectedDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="computedDateFormatted"
                        label="Оберіть дату"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on">

                    </v-text-field>
                  </template>
                  <v-date-picker
                      v-model="selectedDate"
                      range
                      :first-day-of-week="1"
                      locale="uk-Ua"
                      @change="compareDays"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="datePickerMenu = false"
                    >
                      Відміна
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(selectedDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>

                </v-menu>
                <div class="text-center">
                  <v-row class="justify-center">
                    <v-btn
                        class="ma-2"
                        :loading="loading"

                        color="success"
                        @click="loadNewTransactions"
                    >
                      Завантажити
                      <template v-slot:loader>
                        <span>Завантаження...</span>
                      </template>
                    </v-btn>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <change-shift-time-dialog-component></change-shift-time-dialog-component>
                    </span>
                      </template>
                      <span>Змінити операційний час</span>
                    </v-tooltip>
                  </v-row>
                </div>
              </div>

              <div v-if="sumarry.totalOperations >= 1 " style="margin-top: 50px">
                <v-hover v-slot="{ hover }">
                  <v-card
                      :elevation="hover ? 8 : 3"
                      class="mx-auto"

                  >
                    <v-card-text>
                      <div>Підсумок</div>
                      <v-divider></v-divider>

                      <p style="margin-top: 15px">Загальна сума: </p>
                      <p class="text-h4 text--primary">
                        {{ sumarry.totalAmount.toLocaleString('uk-UA') }}₴
                      </p>
                      <p class="text-h6">Кількість транзакцій: {{ sumarry.totalOperations }}</p>
                      <p class="text-h8">Поповнень: {{ sumarry.totalDepositsAmount.toLocaleString('uk-UA') }}₴
                        ({{ sumarry.totalDepositsCounter }})</p>
                      <p class="text-h8">Виплат: {{ sumarry.totalWithdrawAmount.toLocaleString('uk-UA') }}₴
                        ({{ sumarry.totalWithdrawCounter }})</p>
                    </v-card-text>
                    <v-card-actions>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              color="success"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              icon
                              :href="excelDownloadLink"
                              @click="snackbar.isVisible = true"
                          >
                            <v-icon>mdi-microsoft-excel</v-icon>
                          </v-btn>
                        </template>
                        <span>Завантажити .excel</span>
                      </v-tooltip>
                      <v-spacer></v-spacer>
                      <v-switch
                          v-model="chartToggle"
                          inset
                          :label="`Графік`"
                      ></v-switch>
                    </v-card-actions>


                  </v-card>
                </v-hover>
                <v-divider></v-divider>
              </div>


            </v-col>


            <v-col
                md="9"

            >

              <div style="text-align: center;" v-if="sumarry.totalOperations === 0 && !loading">
                <v-img style="margin:auto; margin-top: 50px" :src="require('../assets/royal_logo.png')"
                       max-width="200px"
                ></v-img>
                <p style="margin-top: 50px">Дані ще не завантажнно або відсутні за даний період</p>
              </div>
              <v-card v-if="sumarry.totalOperations >= 1 && !chartToggle">
                <v-card-title>
                  <v-text-field
                      v-model="searchOnTable"
                      append-icon="mdi-magnify"
                      label="Пошук"
                      single-line
                      hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                    :headers="tableHeaders"
                    :items="transactions"
                    :search="searchOnTable"
                    :sort-by.sync="tableHeaders[0].value"
                    sort-desc.sync="true"
                    :footer-props="{
        showFirstLastPage: true,
           'items-per-page-text':'транзакцій на сторінці',

      }"
                >

                  <template v-slot:[`footer.prepend`]>
                    <div style="display: contents;">
                      <small>Операційний день: {{ $store.getters.getShiftTimeStart }} до
                        {{ $store.getters.getShiftTimeEnd }} </small>
                      <small>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                        <span  v-bind="attrs"  v-on="on" >
                          <change-shift-time-dialog-component></change-shift-time-dialog-component>
                        </span>
                          </template>
                          <span>Натисніть для корегування</span>
                        </v-tooltip>
                      </small>

                    </div>
                  </template>

                  <template v-slot:[`item.timestamp`]="{ item }">
              <span :class="{ withdrawTransaction: item.amount < 0}">
                {{
                  new Date(item.timestamp * 1000).toLocaleDateString('uk-UA')
                }} {{ new Date(item.timestamp * 1000).toLocaleTimeString('uk-UA') }}

              </span>
                  </template>
                  <template v-slot:[`item.cardNumber`]="{ item }">
              <span :class="{ withdrawTransaction: item.amount < 0}">
                {{ item.cardNumber }}

              </span>
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
              <span :class="{ withdrawTransaction: item.amount < 0}">
                {{ item.amount.toLocaleString('uk-UA') }}₴

              </span>
                  </template>

                  <template v-slot:[`item.cashier_name`]="{ item }">
                    <v-tooltip>
                      <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon :color="item.amount < 0 ? 'orange darken-2' : ''">mdi-account-circle</v-icon>
                {{ `  ` }}{{ item.cashier_name }}
              </span>
                      </template>
                      <span>Sheet ID: {{ item.shet_id }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>
              <v-card v-if="loading">
                <v-skeleton-loader
                    :loading="false"
                    type="table-tbody"
                ></v-skeleton-loader>
              </v-card>


              <v-card v-if="chartToggle"
                      class="mt-4 mx-auto"

              >
                <v-sheet
                    class="v-sheet--offset mx-auto"
                    color="cyan"
                    elevation="12"
                    max-width="calc(100% - 32px)"
                >
                  <v-sparkline

                      :value="value"
                      color="white"
                      line-width="2"
                      padding="16"
                  ></v-sparkline>
                </v-sheet>

                <v-card-text class="pt-0">
                  <div class="text-h6 font-weight-light mb-2">
                    Графік операцій
                  </div>
                  <div class="subheading font-weight-light grey--text">
                    В процесі розробки... колись тай буде 🤞
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <v-icon
                      class="mr-2"
                      small
                  >
                    mdi-clock
                  </v-icon>
                  <span
                      class="text-caption grey--text font-weight-light">За обраний період {{
                      selectedDate.map(dt => formatDate(dt)).join(' - ')
                    }}</span>
                </v-card-text>
              </v-card>


            </v-col>
          </v-row>
          <v-row class="pa-10" v-for="(cashier, index) in transactionsMetaData"
                 :key="index">
            <v-col

                md="3"
            >
              <div v-if="sumarry.totalOperations >= 1"
                   :class="[index === 0 && !chartToggle ? 'firs-summary-card-class' : '']">
                <v-hover v-slot="{ hover }"
                >
                  <v-card
                      :elevation="hover ? 8 : 3"
                      max-width="calc(100% - 35px)"

                  >
                    <v-card-text>
                      <div>Підсумок по {{ cashier.cashier_name }}</div>

                      <v-divider></v-divider>

                      <p style="margin-top: 15px">Загальна сума: </p>
                      <p class="text-h4 text--primary">
                        {{ sumarryByCashiers[cashier.cashier_name].totalAmount.toLocaleString('uk-UA') }}₴
                      </p>
                      <p class="text-h6">Кількість транзакцій: {{
                          sumarryByCashiers[cashier.cashier_name].totalOperations
                        }}</p>
                      <p class="text-h8">Поповнень:
                        {{ sumarryByCashiers[cashier.cashier_name].totalDepositsAmount.toLocaleString('uk-UA') }}₴
                        ({{ sumarryByCashiers[cashier.cashier_name].totalDepositsCounter }})</p>
                      <p class="text-h8">Виплат:
                        {{ sumarryByCashiers[cashier.cashier_name].totalWithdrawAmount.toLocaleString('uk-UA') }}₴
                        ({{ sumarryByCashiers[cashier.cashier_name].totalWithdrawCounter }})</p>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-switch
                          @change="filterItemsData(sumarryByCashiers[cashier.cashier_name].transactionsListTogle, cashier.cashier_name)"
                          v-model="sumarryByCashiers[cashier.cashier_name].transactionsListTogle"
                          label="транзакції"
                      ></v-switch>
                    </v-card-actions>

                  </v-card>
                </v-hover>
              </div>


            </v-col>

            <v-col
                md="9"

            >


              <div>
                <v-card v-if="sumarryByCashiers[cashier.cashier_name].transactionsListTogle">
                  <v-card-title>
                    <v-text-field
                        v-model="searchOnTable"
                        append-icon="mdi-magnify"
                        label="Пошук"
                        single-line
                        hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                      :headers="tableHeaders"
                      :items="filteredItemsData[cashier.cashier_name]"
                      :search="searchOnTable"
                      locale="uk-UA"
                      :footer-props="{
        showFirstLastPage: true,
           'items-per-page-text':'транзакцій на сторінці',


      }"
                  >
                    <template v-slot:[`footer.prepend`]>
                      <div>
                        <small>Операційний день: {{ $store.getters.getShiftTimeStart }} до
                          {{ $store.getters.getShiftTimeEnd }}</small>
                      </div>
                    </template>

                    <template v-slot:[`item.timestamp`]="{ item }">
              <span :class="{ withdrawTransaction: item.amount < 0}">
                {{
                  new Date(item.timestamp * 1000).toLocaleDateString('uk-UA')
                }} {{ new Date(item.timestamp * 1000).toLocaleTimeString('uk-UA') }}

              </span>
                    </template>
                    <template v-slot:[`item.cardNumber`]="{ item }">
              <span :class="{ withdrawTransaction: item.amount < 0}">
                {{ item.cardNumber }}

              </span>
                    </template>
                    <template v-slot:[`item.amount`]="{ item }">
              <span :class="{ withdrawTransaction: item.amount < 0}">
                {{ item.amount.toLocaleString('uk-UA') }}₴

              </span>
                    </template>

                  </v-data-table>
                </v-card>
              </div>


              <v-card v-if="chartToggle"
                      class="mt-4 mx-auto"

              >
                <v-sheet
                    class="v-sheet--offset mx-auto"
                    color="cyan"
                    elevation="12"
                    max-width="calc(100% - 32px)"
                >
                  <v-sparkline

                      :value="value"
                      color="white"
                      line-width="2"
                      padding="16"
                  ></v-sparkline>
                </v-sheet>

                <v-card-text class="pt-0">
                  <div class="text-h6 font-weight-light mb-2">
                    Графік операцій
                  </div>
                  <div class="subheading font-weight-light grey--text">
                    ...........
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <v-icon
                      class="mr-2"
                      small
                  >
                    mdi-clock
                  </v-icon>
                  <span class="text-caption grey--text font-weight-light">За обраний період</span>
                </v-card-text>
              </v-card>


            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <points-history></points-history>
        </v-tab-item>
        <v-tab-item>
          <tickets-history></tickets-history>
        </v-tab-item>
      </v-tabs>

    </v-container>
  </v-main>
</template>

<script>

import apiService from "@/apiServices/apiService";
import store from "../store";
import ChangeShiftTimeDialogComponent from "@/components/ChangeShiftTimeDialogComponent.vue";
import PointsHistory from "@/components/MainPageTabsView/PointsHistory.vue";
import TicketsHistory from "@/components/MainPageTabsView/TicketsHistory.vue";

export default {
  name: "MainPage",

  components: {TicketsHistory, PointsHistory, ChangeShiftTimeDialogComponent},



  data: () => ({

    snackbar: {isVisible: false, text: 'Очікуйте завантаження...'},
    excelDownloadLink: null,
    hideSelect: false,
    totalView: false,
    datePickerMenu: false,
    selectedDate: new Array((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
    sumarry: {
      totalAmount: 0,
      totalOperations: 0,
      totalWithdrawCounter: 0,
      totalDepositsCounter: 0,
      totalWithdrawAmount: 0,
      totalDepositsAmount: 0
    },
    selectedLocationName: '',
    selectedCashiersNames: [],

    transactionsMetaData: {},
    sumarryByCashiers: {},


    chartToggle: false,
    loading: false,
    selectedLocation: '',
    selectedCashier: '',
    searchOnTable: '',
    tableHeaders: [
      {text: 'Дата/Час', value: 'timestamp'},
      {text: 'Картка', value: 'cardNumber'},
      {text: 'Сума', value: 'amount'},
      {text: 'Касир', value: 'cashier_name'},
    ],
    locations: [],
    cashiers: [],
    transactions: [],

    cashiersList: [],
    filteredItemsData: [],

    locationsWithCashiers: [],


  }),
  methods: {


    async getAllowedDates() {
      const data = await apiService.getLocationsWithCashiers();
      console.log('Cashiers and adm -> ', data.locations_cashiers)
      this.locationsWithCashiers = data.locations_cashiers;

    },


    filterItemsData(val, data) {
      console.log(val, data)
      if (val) {
        this.filteredItemsData[data] = this.transactions.filter(transition => transition.cashier_name === data)
      }
    },

    selectVenueHandler(selectedVal) {
      let convertedFromObserve = JSON.parse(JSON.stringify(this.locationsWithCashiers))
      let cashierList = new Array();
      convertedFromObserve.forEach(el => {
        if (el.locationId === selectedVal) {
          console.log(el)
          cashierList = el.cashiers;
          this.selectedLocationName = el.locationName;
        }
      })
      this.cashiersList = cashierList;
    },

    selectCashiersHandler(seletedValues) {
      let selectedCashiersName = new Array();
      this.cashiersList.forEach(el => {
        if (seletedValues.includes(el.cashier_id)) {
          selectedCashiersName.push(el.cashier_name);
        }
      })
      this.selectedCashiersNames = selectedCashiersName;
    },


    async loadNewTransactions() {

      console.log('Filters: ')
      console.log(this.selectedLocation)
      console.log(this.selectedCashier)

      if(this.selectedLocation === '' || this.selectedCashier === ''){
        this.$store.commit('setSnackMessage', {
          message: 'Оберіть зал та касира!',
          isError: true
        })
        return false;
      }

      // const startTimeStemp = `08:00:00_${this.selectedDate[0]}`;
      // const endTimeStemp = `07:59:00_${this.selectedDate[1] ? this.selectedDate[1] : this.selectedDate[0]}`;

      const startTimeStemp = `${this.$store.getters.getShiftTimeStart}_${this.selectedDate[0]}`
      const endTimeStemp = `${this.$store.getters.getShiftTimeEnd}_${this.selectedDate[1] ? this.selectedDate[1] : this.selectedDate[0]}`;

      this.sumarry = {
        totalAmount: 0,
        totalOperations: 0,
        totalWithdrawCounter: 0,
        totalDepositsCounter: 0,
        totalWithdrawAmount: 0,
        totalDepositsAmount: 0
      };
      try {
        this.loading = true;

        const requestData = `?startDate=${startTimeStemp}&endDate=${endTimeStemp}&locationId=${this.selectedLocation}&cashierIds=${this.selectedCashier}`;
        //
        this.excelDownloadLink = `${window.location.host.includes('localhost') ? 'https://dev.royalcasino.ua' : ''}/api/transactions_location_excel${requestData}`
        console.log('requestData --> ', requestData);

        let transactions = await apiService.getTransactions(requestData)
        console.log('response data -> ', transactions)
        if ('msg' in transactions) {
          console.log('msg is pressent')
          return false;
        }
        this.transactions = transactions.transactions;
        this.transactionsMetaData = transactions.cashiers;


        this.sumarry.totalOperations = transactions.transactions.length;
        this.sumarry.totalAmount = 0;
        this.sumarry.totalDepositsCounter = 0;
        this.sumarry.totalDepositsAmount = 0;
        this.sumarry.totalWithdrawAmount = 0;

        let sumarryByCashiers = {};
        transactions.cashiers.forEach(cashier => {
          sumarryByCashiers[cashier.cashier_name] = {
            totalAmount: 0,
            totalDepositsCounter: 0,
            totalDepositsAmount: 0,
            totalWithdrawCounter: 0,
            totalWithdrawAmount: 0,
            totalOperations: 0,
            cashierName: '',
            transactionsListTogle: false
          };
        });


        console.log('sumarryByCashiers ---->>>>', sumarryByCashiers)
        transactions.transactions.forEach(transaction => {
          this.sumarry.totalAmount = this.sumarry.totalAmount + transaction.amount;
          sumarryByCashiers[transaction.cashier_name]['totalAmount'] = sumarryByCashiers[transaction.cashier_name]['totalAmount'] + transaction.amount;
          sumarryByCashiers[transaction.cashier_name]['totalOperations'] += 1;
          if (transaction.amount < 1) {
            this.sumarry.totalWithdrawCounter += 1;
            this.sumarry.totalWithdrawAmount = this.sumarry.totalWithdrawAmount + Math.abs(transaction.amount);
            sumarryByCashiers[transaction.cashier_name]['totalWithdrawCounter'] += 1;
            sumarryByCashiers[transaction.cashier_name]['totalWithdrawAmount'] = sumarryByCashiers[transaction.cashier_name]['totalWithdrawAmount'] + Math.abs(transaction.amount);
          } else {
            this.sumarry.totalDepositsCounter += 1;
            this.sumarry.totalDepositsAmount = this.sumarry.totalDepositsAmount + Math.abs(transaction.amount);
            sumarryByCashiers[transaction.cashier_name]['totalDepositsCounter'] += 1;
            sumarryByCashiers[transaction.cashier_name]['totalDepositsAmount'] = sumarryByCashiers[transaction.cashier_name]['totalDepositsAmount'] + Math.abs(transaction.amount);
          }

        })

        this.sumarryByCashiers = Object.assign({}, this.sumarryByCashiers, sumarryByCashiers);
        console.log('sumarryByCashiers -> ', sumarryByCashiers)

        this.loading = false;
        this.hideSelect = true;
      } catch (e) {
        console.log('get transactions error -> ', e);
        this.loading = false;
        this.$store.commit('setSnackMessage', {
          message: `❕${e}`,
          isError: true
        })
      }


    },

    getColor(amount) {
      if (amount < 0) return 'warning'
      else return 'success'
    },

    getArrowIcon(amount) {
      if (amount < 0) return 'mdi-arrow-top-right'
      else return 'mdi-arrow-bottom-left'
    },
    compareDays(val) {
      console.log('in compares -> ', val)
      const startDay = new Date(String(this.selectedDate[0]));
      const endDay = new Date(String(this.selectedDate[1]));
      const strStartDate = this.selectedDate[0];
      const strEndDate = this.selectedDate[1];
      if (startDay > endDay) {
        this.selectedDate[0] = strEndDate;
        this.selectedDate[1] = strStartDate;
      }
      console.log('creconverted date -> ', this.selectedDate)
      // this.$refs.menu.save(this.selectedDate);
    },


    formatDate(dates) {
      console.log(dates, typeof dates)
      if (!dates) return null;
      if (!Array.isArray(dates)) dates = new Array(dates)
      return dates.map(date => {
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
      })

    },

  },
  computed: {
    store() {
      return store
    },
    computedDateFormatted() {
      return this.formatDate(this.selectedDate);
    },
  },

  mounted() {
    this.getAllowedDates()
  }
}
</script>

<style scoped>



.firs-summary-card-class {
  /*margin-top: -150px;*/
}


</style>

<style>
.withdrawTransaction {
  color: #fb8c00;
}
</style>
