<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-bottom-transition"
        max-width="530"
    >

      <v-card>
        <v-toolbar
            color="primary"
            dark
        >
          <span class="text-md-h5">[{{ actionData.text.toUpperCase() }}] оберіть дію</span>
        </v-toolbar>

        <v-card-text>
          <div class="text-center pa-10">
            <v-btn class="mx-4 text--white"
                   outlined
                   elevation="8"
                   color="light-blue darken-1"
                   :disabled="isUpdateLoading"
                   @click="uploadData"
            >
              Завантажити
              <v-icon
                  right
                  dark
              >
                mdi-cloud-upload
              </v-icon>
            </v-btn>
            <v-btn
                elevation="8"
                class="mx-4"
                color="success"
                outlined
                :disabled="isUpdateLoading"
                :loading="isUpdateLoading"
                @click="sendFirebaseActionMsg"
            >
              Оновити {{!actionData.firebaseToken ? 'Усі' : ''}}
              <v-icon
                  right
              >
                mdi-cached
              </v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="closeModal"
          >Скасувати
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <game-config-dialog v-if="configDialog" @on-game-config-closed="gameConfigDialogClosed" :device-id="actionData.deviceId" :firebaseToken="actionData.firebaseToken"></game-config-dialog>
    <change-kiosk-background v-if="isKioskBgEditorVisible" :firebaseToken="actionData.firebaseToken" :device-id="actionData.deviceId"
                             :is-dialog-visible="isKioskBgEditorVisible"
                             @close-changer="toggleShowEditKioskBg"></change-kiosk-background>
  </div>
</template>
<script>
import apiService from "@/apiServices/apiService";
import ChangeKioskBackground from "@/components/ChangeKioskBackground.vue";
import GameConfigDialog from "@/components/GameConfigDialog.vue";

export default {
  name: "ActionChoiserDialog",
  components: {GameConfigDialog, ChangeKioskBackground},
  props: {
    actionData: {
      type: Object,
      default: function () {
        return {text: '', action: null, isLoading: false, color: 'primary', firebaseToken: null, deviceId: null}
      }
    }
  },
  data() {
    return {
      configDialog: false,
      dialog: true,
      isUpdateLoading: false,
      isKioskBgEditorVisible: false
    }
  },
  mounted() {
    console.log('ActionChoiserDialog mounted')
  },
  methods: {

    gameConfigDialogClosed() {
      this.configDialog = false;
      this.closeModal()
    },
    uploadData() {
      if(this.actionData.action === 'update_bg'){
        this.isKioskBgEditorVisible = true
      }else {
        // alert('Not implemented yet')
        this.configDialog = true
      }


    },

    closeModal() {
      this.$emit('choiserClose')
    },

    toggleShowEditKioskBg(){
      this.isKioskBgEditorVisible = false
      this.closeModal()
    },

    async sendFirebaseActionMsg() {
      try {
        this.isUpdateLoading = true
        const body = {action: this.actionData.action}

        console.log('[i] firebaseToken: ', this.actionData.firebaseToken)

        if (this.actionData.firebaseToken) {
          console.log('Sending to specific device')
          body['tokens'] = new Array(this.actionData.firebaseToken)
        }else {
          console.log('Sending to all devices')
          body['topic'] = 'royal_kiosks'
        }

        const fbMsgResponse = await apiService.sendFirebasePushMsg(body)
        console.log(fbMsgResponse)
        if (!fbMsgResponse['success']) {
          alert(`🔴 ERROR\n${fbMsgResponse['error']}`)
        } else {
          alert(`✅ Готово`);
          this.closeModal()
        }
      } catch (e) {
        alert(`🔴 ERROR\n${e}`)
      } finally {
        this.isUpdateLoading = false
      }

    },
  }
}
</script>

<style scoped>

</style>