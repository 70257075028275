const state = {
    snackMessage: []
};

const getters = {
    getSnackMessage: state => state.snackMessage
};

const actions = {
    setSnackMessage({commit}, {message: message, icon: icon, isError: isError = false, timeout: timeout = 4500}) {
        commit('setSnackMessage', {message: message, icon: icon, isError: isError, timeout: timeout});
    },
    clearSnackMessage({commit}) {
        commit('clearSnackMessage');
    }
};
const mutations = {
    setSnackMessage(state, {message: message, icon: icon, isError: isError = false, timeout: timeout = 4500}) {
        state.snackMessage.push({
            message: `${icon ? message + '_mdi-icon=' + icon : (isError ? message + '_mdi-icon=' + 'mdi-alert-circle-outline' : message)}`,
            color: isError ? 'red darken-3' : 'black',
            timeout: timeout
        });
    },
    clearSnackMessage(state) {
        console.log('clear snack')
        state.snackMessage = [];
    }
};

export default {
    actions,
    state,
    getters,
    mutations,
};
