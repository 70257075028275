<template>
  <v-dialog
      v-model="createCashierDialog"
      persistent
      max-width="600px"
  >
    <v-snackbar
        v-model="snackbar"
        color="warning"
        absolute
        top
        right
        elevation="24"
        centered
        text
        timeout="3500">
      Заповніть обов'язкові поля
      <template v-slot:action="{ attrs }">
        <v-btn
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card>
      <v-progress-linear v-if="loading"
                         :active="loading"
                         :indeterminate="loading"
                         height="6px"
      ></v-progress-linear>
      <div v-if="loading" style="padding-top:20px; text-align: center">Очікуйте...

      </div>
      <v-card-title>
        <span class="text-overline mb-lg-7">Новий касир: <span>{{success ? 'Успішно створений' : ''}}</span> </span>
      </v-card-title>
      <v-card-text>
        <v-form
            ref="form"
            lazy-validation
        >
          <v-col>
            <v-text-field
                :disabled="success"
                v-model="cashier.name"
                :counter="19"
                label="Назва касира"
                required
                append-icon="mdi-account-plus"
            ></v-text-field>
          </v-col>

          <v-autocomplete
              :items="getAllAvalibleData"
              :item-text="'locationName'"
              :item-value="'locationId'"
              v-model="cashier.locationId"
              label="Локація*"
              required

              :disabled="success"
          ></v-autocomplete>
          <v-text-field
              v-model="cashier.comment"
              label="Коментар"
              :disabled="success"
          ></v-text-field>


          <v-btn
              :disabled="success"
              color="primary"
              class="mr-4"
              @click="createCashier"
          >
            Створити
          </v-btn>




        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :disabled="loading"
            color="blue darken-1"
            text
            @click="closeModal"
        >
          Закрити
        </v-btn>

      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import apiService from "@/apiServices/apiService";

export default {
  name: "CreateNewCashier",
  props: {
    value: Boolean,
    avalibleLocations: Array,
  },
  data: ()=>({
    snackbar: false,
    loading: false,
    success: false,
    alavibleData: [],
    cashier: {name: '', comment: '', locationId: ''},

  }),
  methods: {

    async createCashier(){
      console.log('new cashier --> ', this.cashier)
      if (this.cashier.name.length > 3 && this.cashier.locationId.length !=0) {
        this.loading = true;
        this.success = true;
        try {
          const createCashierResp = await apiService.createCashiers(this.cashier);
          this.loading = false;
          if(createCashierResp.success){
            this.$store.commit('setSnackMessage', {
              message: 'Касира успішно створено',
              icon: 'mdi-check',
            })
          }else {
            this.$store.commit('setSnackMessage', {
              message: `Помилка \n${createCashierResp.error}`,
              isError: true
            })
            this.success = false;
          }
        }catch (e) {
          this.$store.commit('setSnackMessage', {
            message: `Помилка \n${e}`,
            isError: true
          })
          this.loading = false;
          this.success = false;
        }

      } else {
        this.snackbar = true;
      }
    },
    closeModal() {
      this.reset();
      this.$store.commit("changeUpdCashiersList", !this.$store.getters.isNedUpdCashiersList);
      this.createVenueDialog = false;
      this.createCashierDialog = false;

    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },

  computed: {
    createCashierDialog: {
      get(){
        return this.value
      },
      set(value){
        this.$emit('input', value)
      }

    },
    getAllAvalibleData: {
      get() {
        console.log('inn computed dta -> ', this.avalibleLocations)
        return this.avalibleLocations
      }
    },
  }
}
</script>

<style scoped>

</style>
