import {authService} from './authApiService'
import store from '@/store';
import router from "@/router";
import {authHeader, authHeaderPython} from "@/apiServices/customBaseAuthHalper";
import urls from "@/apiServices/urls";

const spammerServiceUrl = urls.spammerServiceUrl;


export default {


    async LogIn(User) {
        console.log('in log in api service.....', User)
        return await authService.post('/login', User).then(response => {
            console.log(response.headers)
            return response.data
        })

        // await commit('setUser', User.get('username'))
    },

    async LogOut({commit}) {
        let user = null
        commit('logout', user)
    },

    async GetPosts({commit}) {
        let response = await authService.get('posts')
        commit('setPosts', response.data)
    },

    getLocationsWithCashiers() {
        return authService.get(`/get_locations_cashiers`)
            .then(response => {
                console.log('response -> ', response)
                return response.data
            })
    },

    getTransactions(params) {
        return authService.get(`/transactions_location${params}`)
            .then(response => {
                return response.data
            })
    },

    getUniqueLocationsTickets() {
        return authService.get(`/unique_tickets_locations`)
            .then(response => {
                return response.data
            })
    },

    getPointsTransactions(params) {
        return authService.get(`/transactions_location_points${params}`)
            .then(response => {
                return response.data
            })
    },

    getTicketsTransactions(params) {
        return authService.get(`/transactions_tickets${params}`)
            .then(response => {
                return response.data
            })
    },


    getLocations() {
        return authService.get('/get_locations').then(response => {
            return response.data
        })
    },
    createLocation(locationData) {
        return authService.post('/create_location', locationData).then(response => {
            return response.data
        })
    },


    deleteLocation(locationId) {
        return authService.delete(`/delete_location/${locationId}`).then(response => {
            return response.data
        })
    },


    getDevices() {
        return authService.get('/get_devices').then(response => {
            return response.data
        })
    },

    createDevice(deviceData) {
        return authService.post('/create_device', deviceData).then(response => {
            return response.data
        })
    },

    editDevice(newDeviceData) {
        return authService.put('/edit_device', newDeviceData).then(response => {
            return response.data
        })
    },

    getCashiers() {
        return authService.get('/get_cashiers').then(response => {
            return response.data
        })
    },

    createCashiers(cashierData) {
        return authService.post('/create_cashier', cashierData).then(response => {
            return response.data
        })
    },

    editCashiers(cashierData) {
        return authService.put('/edit_cashier', cashierData).then(response => {
            return response.data
        })
    },

    getApkVersion() {
        return authService.get('/app_versions').then(response => {
            return response.data
        })
    },

    uploadNewApk(uploadData) {

        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        let formData = new FormData();

        formData.append("deviceType", uploadData.deviceType);
        formData.append("apkFile", uploadData.file);
        formData.append("version", uploadData.name);
        formData.append("changeLog", uploadData.changeLog);

        console.log('form data >>> ', formData);
        return authService.post(`/upload_new_app_version`, formData, {
            headers,
            onUploadProgress: (progressEvent) => {
                // const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                const percentCompleted = Math.round(progressEvent.loaded / progressEvent.total * 100);
                console.log(percentCompleted);
                store.commit("updateProgressVal", percentCompleted);
                console.log('frome store -> ', store.getters.getProgressVal)
                // if (percentCompleted == 100){
                //     store.commit("updateProgressVal", 0);
                // }

            }
        }).then(response => {
            store.commit("updateProgressVal", 0);
            return response.data;
        })


    },


    uploadImg(imgFile) {

        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        let formData = new FormData();


        formData.append("spam_image", imgFile);


        console.log('form data >>> ', formData);
        return authService.post(`/upload_spam`, formData, {
            headers,
            onUploadProgress: (progressEvent) => {
                // const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                const percentCompleted = Math.round(progressEvent.loaded / progressEvent.total * 100);
                console.log(percentCompleted);
                store.commit("updateProgressVal", percentCompleted);
                console.log('frome store -> ', store.getters.getProgressVal)

            }
        }).then(response => {
            store.commit("updateProgressVal", 0);
            return response.data;
        })


    },


    uploadKioskBg(mediaFile, deviceId = null) {

        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        let formData = new FormData();

        formData.append("media", mediaFile);
        if (deviceId) {
            formData.append("deviceId", deviceId);
        }


        console.log('form data >>> ', formData);
        return authService.put(`/kiosk/uploadKioskBackground`, formData, {
            headers,
            onUploadProgress: (progressEvent) => {
                // const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                const percentCompleted = Math.round(progressEvent.loaded / progressEvent.total * 100);
                console.log(percentCompleted);
                store.commit("updateProgressVal", percentCompleted);
                console.log('frome store -> ', store.getters.getProgressVal)

            }
        }).then(response => {
            store.commit("updateProgressVal", 0);
            return response.data;
        })


    },


    sendFirebasePushMsg({tokens: tokens, topic: topic, action}) {

        const body = {action: action}
        if (tokens) {
            body.tokens = tokens
        }
        if (topic) {
            body.topic = topic
        }
        console.log('body data >>> ', body);
        return authService.post('/kiosk/send_action', body).then(response => {
            return response.data
        })

    },

    async getGameConfigs(deviceId) {
        const authH = await authHeaderPython(deviceId, true);
        return fetch(`${urls.baseURL}/kiosk/gamesConfigs/?deviceId=${deviceId}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Auth": authH
            },
        }).then(response => response.json())
        // return authService.get(`/kiosk/gamesConfigs/${deviceId ? '?deviceId=' + deviceId : ''}`).then(response => {
        //     return response.data
        // })
    },

    uploadGameConfig(deviceId, configs) {
        const body = {
            configs: configs
        }
        if(deviceId){
            body.deviceId = deviceId;
        }
        return authService.put(`/kiosk/uploadGamesConfigs`, body).then(response => {
            return response.data
        })
    },

    async sendOneMsg(botType, spamText, userId, imgUrl) {
        const auname = store.getters.StateUser;
        console.log('Auname: ', auname)
        const body = {
            spamText: spamText,
            userId: userId
        }
        if (imgUrl) {
            body.imgUrl = imgUrl;
        }
        const authH = await authHeader(auname, body);
        return fetch(`${spammerServiceUrl}/api/sendmsg/${botType}`, {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "content-type": "application/json",
                "Auname": auname,
                "Authorization": authH
            },
            body: JSON.stringify(body)
        }).then(async (response) => {
            console.log('response: ', response)
            if (response.status === 401) {
                await store.dispatch("LogOut");
                await router.push('/');
            }
            return response.status !== 200 ? {
                success: false,
                error: `Status Code: ${response.status} ${response.statusText}`
            } : response.json()
        }).catch(err => {
            return {error: err}
        })

    },

    async sendSpam(spamText, imgUrl) {
        const auname = store.getters.StateUser;
        console.log('Auname: ', auname)
        const body = {
            spamText: spamText
        }
        if (imgUrl) {
            body.imgUrl = imgUrl;
        }
        const authH = await authHeader(auname, body);
        return fetch(`${spammerServiceUrl}/api/sendspam`, {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "content-type": "application/json",
                "Auname": auname,
                "Authorization": authH
            },
            body: JSON.stringify(body)
        }).then(async (response) => {
            console.log('response: ', response)
            if (response.status === 401) {
                await store.dispatch("LogOut");
                await router.push('/');
            }
            return response.status !== 200 ? {
                success: false,
                error: `Status Code: ${response.status} ${response.statusText}`
            } : response.json()
        }).catch(err => {
            return {error: err}
        })

    },

    async getSpamHistory() {
        const auname = store.getters.StateUser;
        console.log('Auname: ', auname)

        const authH = await authHeader(auname, {});
        return fetch(`${spammerServiceUrl}/api/spamhistory`, {
            "method": "GET",
            "headers": {
                "accept": "application/json",
                "content-type": "application/json",
                "Auname": auname,
                "Authorization": authH
            },
        }).then(async (response) => {
            console.log('response: ', response)
            if (response.status === 401) {
                await store.dispatch("LogOut");
                await router.push('/');
            }
            return response.status !== 200 ? {
                success: false,
                error: `Status Code: ${response.status} ${response.statusText}`
            } : response.json()
        }).catch(err => {
            return {error: err}
        })

    },

    async getBotUsersList() {
        const auname = store.getters.StateUser;
        console.log('Auname: ', auname)

        const authH = await authHeader(auname, {});
        return fetch(`${spammerServiceUrl}/api/botuserslist`, {
            "method": "GET",
            "headers": {
                "accept": "application/json",
                "content-type": "application/json",
                "Auname": auname,
                "Authorization": authH
            },
        }).then(async (response) => {
            console.log('response: ', response);
            if (response.status === 401) {
                await store.dispatch("LogOut");
                await router.push('/');
            }
            return response.status !== 200 ? {
                success: false,
                error: `Status Code: ${response.status} ${response.statusText}`
            } : response.json()
        }).catch(err => {
            return {error: err}
        })

    },


}
