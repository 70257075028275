<template>

  <v-app-bar v-if="!$route.meta.hideNavbar"
      app
      color="primary"
      dark
  >
    <div class="d-flex align-center">
      <v-img
          alt="Royal Logo"
          class="shrink mr-2"
          contain
          src="https://royal-casino.ua/wp-content/uploads/2020/11/cropped-Logo-2.png"
          transition="scale-transition"
          width="100"
      />

    </div>

    <v-spacer></v-spacer>

    <span>v{{appVersion}}</span>
    <span v-if="isLoggedIn && isUserAdmin">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="goToPage('/mainpage')"
                 icon v-bind="attrs" v-on="on">
            <v-icon>mdi-chart-bar</v-icon>
          </v-btn>
        </template>
        <span>Статистика</span>
      </v-tooltip>
    </span>

    <span v-if="isLoggedIn && (isUserAdmin || isUserSpammer)">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="goToPage('/spam')"
                 icon v-bind="attrs" v-on="on">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </template>
        <span>Розсилка</span>
      </v-tooltip>
    </span>

    <span v-if="isLoggedIn && isUserAdmin">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="goToPage('/settings')"
                 icon v-bind="attrs" v-on="on">
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </template>
        <span>Налаштування</span>
      </v-tooltip>
    </span>



    <span v-if="isLoggedIn">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="logout"
                 icon v-bind="attrs" v-on="on">
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        <span>Вийти</span>
      </v-tooltip>
    </span>


  </v-app-bar>


</template>
<script>
import packageInfo from '../../package.json'
export default {
  name: "NavBar",

  data: () => ({
    appVersion: packageInfo.version,
  }),
  computed: {
    isLoggedIn: function () {
      console.log(this.$store.getters.isAuthenticated)
      return this.$store.getters.isAuthenticated;
    },
    isUserAdmin: function (){
      return this.$store.getters.checkUserRole === 'ADMIN';
    },
    isUserSpammer: function (){
      return this.$store.getters.checkUserRole === 'SPAMMER';
    },

  },
  methods: {
    async logout() {
      console.log('btn')
      await this.$store.dispatch("LogOut");
      this.$router.push("/");
    },

    goSettings(){
      this.$router.push("/settings");
    },
    goToPage(path){
      if(path !== this.$route.path){
        this.$router.push(path)
      }
    },

    goMain(){
      console.log(this.$route.path)
      this.$router.push("/mainpage");
    },
     goToSpammer(){
       this.$router.push("/spam");
     }
  },
};
</script>

<style scoped>

</style>
