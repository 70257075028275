<template>
  <div>
    <v-expansion-panels >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>Actions <sup v-if="!firebaseToken" class="pa-2">(для усіх)</sup></div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
              align-content="space-between"
              justify="start"
              style="padding: 10px 0 10px 0"
          >
            <device-actions-component :device="device"></device-actions-component>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import DeviceActionsComponent from "@/components/FireBaseActions/DeviceActionsComponent.vue";

export default {
  name: "ExpansionActionsWrapperComponent",
  components: {DeviceActionsComponent},
  props: {
    firebaseToken: {
      type: String,
      default: null
    },
    isActive: {
      type: Boolean,
      default: false
    },
    deviceId: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'KIOSK'}
  },
  computed: {
    device(){
      return {
        isActive: this.isActive,
        type: this.type,
        firebaseToken: this.firebaseToken,
        deviceId: this.deviceId
      }
    }
  }
}
</script>

<style scoped>

</style>