
<template>
  <v-col cols="auto">
    <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon x-small v-bind="attrs" v-on="on" elevation="2">
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
      </template>
      <template >
        <v-card>
          <v-toolbar
              color="primary"
              dark
          >
            Змінити час Операційного дня
          </v-toolbar>
          <v-card-text>
            <v-row class="pa-4">
              <v-col cols="auto" class="pa-4">
                <v-text-field   label="Початок" v-model="startTime" type="time" @input="onStartTimeChanged"></v-text-field>
              </v-col>
              <v-col cols="auto" class="pa-4">
                <v-text-field append-icon="mdi-clock-end" label="Кінець" type="time" v-model="endTime" disabled></v-text-field>
              </v-col>
              <v-col>
                <small>По замовченню з 07:00:00 до 06:59:00 наступного дня</small>
                <v-divider></v-divider>
                <span>Після зміни, необхідно повторно завантажити дані</span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                text
                @click="closeModal(false)"
            >Відмінити</v-btn>
            <v-btn
                text
                @click="closeModal(true)"
            >Зберегти</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-col>
</template>


<script>
export default {
  name: "ChangeShiftTimeDialogComponent",
  data: () => ({
    dialog: false,
    startTime: null,
    endTime: null
  }),
  methods: {
    async onStartTimeChanged(){
      const hour = Number(this.startTime.split(':')[0]);
      this.endTime = (hour === 0 ? 23 : (hour - 1).toString().padStart(2, '0')) + ':59:00'
    },
    closeModal(isNeedToSaveData = false){
      if(isNeedToSaveData){
        this.$store.commit('changeShiftTime', {shiftTimeStart: this.startTime+ ':00',  shiftTimeEnd: this.endTime})
      }else{
        this.startTime = this.$store.getters.getShiftTimeStart
        this.endTime = this.$store.getters.getShiftTimeEnd
      }
      this.dialog = false;
    }
  },
  mounted() {
    this.startTime = this.$store.getters.getShiftTimeStart
    this.endTime = this.$store.getters.getShiftTimeEnd
  }

}
</script>

<style scoped>

</style>