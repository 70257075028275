const state = {
    nedUpdLocations: false, nedUpdCashiers: false, nedUpdDevice: false
};

const getters = {
    isNedUpdLocationsList: state =>{
        return state.nedUpdLocations
    },
    isNedUpdCashiersList: state => state.nedUpdCashiers,
    isNedUpdDeviceList: state => state.nedUpdDevice,
};

const actions = {}

const mutations = {
    changeUpdLocationsList (state, data){
        state.nedUpdLocations = data;
    },
    changeUpdCashiersList (state, data){
        state.nedUpdCashiers = data;
    },
    changeUpdDeviceList (state, data){
        state.nedUpdDevice = data;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};
