<template>
  <v-container fluid>

    <v-dialog
        v-if="showSendSpamDialog"
        v-model="showSendSpamDialog"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"

    >

      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-toolbar-title>{{ directSpamUserData.botType }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                icon
                dark
                @click="closeDirectSpamDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <send-spam :is-only-one-direct-spam="true" :user-data="directSpamUserData"></send-spam>
      </v-card>

    </v-dialog>


    <v-card v-if="usersListData">
      <v-card-title>
        {{ usersListData.length }} користувачів
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Пошук"
            single-line
            hide-details
            clearable
        ></v-text-field>
      </v-card-title>
      <v-card-subtitle>
        <b>Телеграм: {{ botUsersCount.Telegram }} шт.
          ({{ ((botUsersCount.Telegram / usersListData.length) * 100).toFixed(2) }}%) | Viber: {{ botUsersCount.Viber }}
          шт. ({{ ((botUsersCount.Viber / usersListData.length) * 100).toFixed(2) }}%)</b>
      </v-card-subtitle>
      <v-data-table
          :headers="headers"
          :items="usersListData"
          :search="search"
          item-key="_id"
          show-expand
      >

        <template v-slot:expanded-item="{headers, item }">
          <td :colspan="headers.length">
            <div style="margin: 1%"><span><b>Id:</b> {{ item.userId }}</span>
              <br>
              <div style="padding: 0.2%" v-for="(h, i) in item.statusHistory" :key="i">
                <span><b>Description: </b>{{ h.statusDetail.description }}</span> | <span>{{ h.modifiedOn }}</span> |
                <span><b>isAuthByPhone:</b> {{ h.statusDetail.isAuthByPhone }}</span>
                <br>
              </div>
            </div>
          </td>
        </template>

        <template v-slot:[`item.botType`]="{ item }">
          <v-avatar size="40" @dblclick="sendDirectSpam(item)">
            <img
                :src="require(`../assets/${item.botType.toLowerCase()}Icon.png`)"
                alt="botLogo"
            >
          </v-avatar>

        </template>

        <template v-slot:[`item.viberUserProfileData.avatar`]="{ item }">
          <div style="margin: 10%">
            <a v-if="'viberUserProfileData' in item ? ('avatar' in item.viberUserProfileData ? (item.viberUserProfileData.avatar ? item.viberUserProfileData.avatar :false ) : false) : false"
               :href="item.viberUserProfileData.avatar" target="_blank">
              <v-avatar>
                <img
                    :src="item.viberUserProfileData.avatar"
                    alt="Avatar"
                >
              </v-avatar>
            </a>
            <v-avatar v-else :color="item.botType === 'Telegram' ? 'primary' : 'purple'">
              <v-icon dark>
                mdi-account-circle
              </v-icon>
            </v-avatar>
          </div>
        </template>

        <template v-slot:footer>
          <vue-excel-xlsx
              :data="usersListData"
              :columns="xlsColumns()"
              :file-name="`BotsUsers_${new Date().toLocaleDateString(
                  'uk-UA'
                )}`"
              :file-type="'xlsx'"
              :sheet-name="'sheet'"
              style="position: absolute; left: 10px; bottom: 10px"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="green"
                    icon
                    variant="text"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-microsoft-excel</v-icon>
                </v-btn>
              </template>
              <span>Завантажити .xlsx</span>
            </v-tooltip>
          </vue-excel-xlsx>
        </template>

      </v-data-table>
    </v-card>


    <div v-else>
      <v-progress-linear
          indeterminate
      ></v-progress-linear>
      <v-skeleton-loader
          type="table-row-divider@6"
      ></v-skeleton-loader>
    </div>
  </v-container>
</template>

<script>
import apiService from "@/apiServices/apiService";
import sendSpam from "@/components/SendSpam.vue";

export default {
  name: "SpamUsersList",
  components: {
    sendSpam
  },
  data: () => ({
    showSendSpamDialog: false,
    search: '',
    headers: [
      {text: 'Бот', value: 'botType', align: 'center'},
      {text: 'Аватар', value: 'viberUserProfileData.avatar', align: 'center'},
      {text: 'Username', value: 'name', align: 'center'},
      {text: 'Телефон', value: 'phoneNumber', align: 'center'},
      {text: 'Авторизований', value: 'isAuthByPhone', align: 'center'},
      {text: 'Від', value: 'createOn', align: 'center'},

    ],
    usersListData: null,
    directSpamUserData: null,
    botUsersCount: {
      Telegram: 0,
      Viber: 0
    }

  }),
  methods: {
    xlsColumns() {
      return this.headers.reduce((accumulator, header) => {
        if (header.text !== 'Аватар') {
          accumulator.push({
            'label': header.text,
            'field': header.value
          });
        }
        return accumulator;
      }, [])
    },
    closeDirectSpamDialog() {
      this.directSpamUserData = null;
      this.showSendSpamDialog = false;

    },
    async sendDirectSpam(userData) {
      console.log('Send direct spam to: ', userData)
      this.showSendSpamDialog = true;
      this.directSpamUserData = userData;
    },
    async getUsersList() {
      try {
        const historyResponse = await apiService.getBotUsersList();
        if (historyResponse.success) {
          if (historyResponse.result.length >= 1) {
            console.log(historyResponse.result)
            this.usersListData = historyResponse.result;
            this.usersListData.forEach(user => {
              this.botUsersCount[user['botType']]++;
            })
          } else {
            console.log('No users records')
            alert('No users records')
          }
        } else {
          alert(`${historyResponse.error}`)
        }
      } catch (e) {
        alert(`${e}`)
      }
    },
  },

  async mounted() {
    console.log('Spam User list Mounted: ')
    await this.getUsersList();
  },
}
</script>

<style scoped>

</style>
