import { render, staticRenderFns } from "./LocationsSettingsPage.vue?vue&type=template&id=31251451&scoped=true&"
import script from "./LocationsSettingsPage.vue?vue&type=script&lang=js&"
export * from "./LocationsSettingsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31251451",
  null
  
)

export default component.exports