import urls from "@/apiServices/urls";

async function hashFromStrf(str, hAlg = 'SHA-512') {
    const algorithm = {name: "HMAC", hash: hAlg};
    const enc = new TextEncoder();
    const hashBuffer = await crypto.subtle.digest(algorithm.hash, enc.encode(str));
    const hashFromIpArray = Array.from(new Uint8Array(hashBuffer));
    return hashFromIpArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');

}

const authHeader = async (auname, body) => {
    const currentTime = new Date();
    let bodyData = JSON.stringify(body)
    const ip = await fetch(`${urls.spammerServiceUrl}/getIpAddress/`).then(res => res.text());
    const username = await hashFromStrf(`${currentTime.getDay()}${currentTime.getMonth()}${auname}${currentTime.getDate()}${bodyData}`)
    const pwd = await hashFromStrf(`${currentTime.getHours()}${username}${currentTime.getDate()}${currentTime.getDay()}${currentTime.getMonth()}${ip}`)
    return 'Basic ' + btoa(username + ":" + pwd)

}

const authHeaderPython = async (body, isGetRequest = false) => {
    let dataFromBody = JSON.stringify(body)
    if (isGetRequest) {
        dataFromBody = 'GetDataFromQuery'
    }
    const ip = await fetch(`${urls.baseURL.replace('/api', '')}/getIpAddress`).then(res => res.text());
    const algorithm = {name: "HMAC", hash: "SHA-512"};
    const enc = new TextEncoder();
    const hashBuffer = await crypto.subtle.digest(algorithm.hash, enc.encode(ip + dataFromBody.split('').reverse().join('')));
    const hashFromIpArray = Array.from(new Uint8Array(hashBuffer));
    const hashHexSecret = hashFromIpArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
    const key = await crypto.subtle.importKey(
        "raw",
        enc.encode(hashHexSecret),
        algorithm,
        false,
        ["sign", "verify"]
    );
    const signature = await crypto.subtle.sign(
        algorithm.name,
        key,
        enc.encode(dataFromBody)
    );
    const hashArray = Array.from(new Uint8Array(signature));
    const encHashStr = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
    return encHashStr
}

export {authHeader, authHeaderPython}
