import axios from "axios";
import apiService from "@/apiServices/apiService";

const state = {
    user: null,
    role: null
};

const getters = {
    isAuthenticated: (state) => {
        console.log('is auth state -> ', state.user)
        return state.user
    },
    checkUserRole: (state) => state.role,
    StatePosts: (state) => state.posts,
    StateUser: (state) => state.user,
};

const actions = {
    async Register({dispatch}, form) {
        await axios.post('register', form)
        let UserForm = new FormData()
        UserForm.append('username', form.username)
        UserForm.append('password', form.password)
        await dispatch('LogIn', UserForm)
    },

    async LogIn({commit}, user) {
        console.log('in auth Log in USER data ', user)
        const logInResp =  await apiService.LogIn(user);
        console.log('logInResp -> ', logInResp)
        if('error' in logInResp){
            throw Error(logInResp.error)
        }
        await commit("setUser", logInResp);
    },

    async CreatePost({ dispatch }, post) {
        await axios.post("post", post);
        return await dispatch("GetPosts");
    },

    async GetPosts({ commit }) {
        let response = await axios.get("posts");
        commit("setPosts", response.data);
    },

    async LogOut({ commit }) {
        const user = {user: null, role: null}
        commit("logout", user);
    },
};

const mutations = {
    setUser(state, user) {
        state.user = user.username;
        state.role = user.role;
    },

    setPosts(state, posts) {
        state.posts = posts;
    },
    logout(state, user) {
        state.user = user.user;
        state.role = user.role;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
