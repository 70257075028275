<template>
  <v-dialog
      v-model="updApkDialog"
      persistent
      max-width="600px"
  >
    <v-snackbar
        v-model="snackbar"
        color="warning"
        absolute
        top
        right
        elevation="24"
        centered
        text
        timeout="3500">
      Назва локаці відсутня
      <template v-slot:action="{ attrs }">
        <v-btn
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card>
      <v-progress-linear v-if="loading"
                         :active="loading"
                         :indeterminate="loading"
                         height="6px"
      ></v-progress-linear>
      <div v-if="loading" style="padding-top:20px; text-align: center">Очікуйте...

      </div>
      <v-card-title>
        <span class="text-overline mb-lg-7">Нова APK: {{ updateData.name }} <span
            style="color: #4caf50">{{ success ? '✅ успішно завантажена на сервер' : '' }}</span></span>
      </v-card-title>
      <v-card-subtitle>
        <v-radio-group
            v-model="updateData.deviceType"
            row
        >
          <v-radio
              label="Aisino"
              value="aisino"
          ></v-radio>
          <v-radio
              label="Other"
              value="other"
          ></v-radio>
        </v-radio-group>
      </v-card-subtitle>
      <v-card-text>
        <v-form
            ref="form"
            lazy-validation
        >
          <v-col v-if="loading" class="text-center">
            <v-progress-circular
                :rotate="-90"
                :size="100"
                :width="15"
                :value="$store.getters.getProgressVal"
                color="primary"
                :indeterminate="true"
            >
              {{ $store.getters.getProgressVal }}
            </v-progress-circular>
          </v-col>
          <v-col>
            <v-file-input
                chips
                counter
                show-size
                label="файл .apk"
                ref="fileupload"
                @change="onFilePicked"


            ></v-file-input>
          </v-col>


          <v-text-field
              v-model="updateData.changeLog"
              label="Чендж лог через КОМУ"
              :disabled="success"
          ></v-text-field>


          <v-btn
              :disabled="success || !updateData.deviceType"
              color="primary"
              class="mr-4"
              @click="uploadApk"
          >
            <v-icon
                left
                dark
            >
              mdi-cloud-upload
            </v-icon>
            Відвантажити
          </v-btn>


        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :disabled="loading"
            color="blue darken-1"
            text
            @click="closeModal"
        >
          Закрити
        </v-btn>

      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import apiService from "@/apiServices/apiService";


export default {
  name: "UploadApk",
  comments: {apiService},
  props: {
    value: Boolean,
  },
  data: () => ({
    snackbar: false,
    loading: false,
    success: false,
    fname: '',
    uploadApkProgressVal: 20,
    updateData: {name: '', changeLog: '', file: null, deviceType: null},
  }),
  methods: {

    onFilePicked(file) {

      if (file) {
        console.log('file picked  ', this.updateData);
        console.log('file picked NEW  ', file.target);
        console.log(file)
        console.log(file.name)
        let t = file.name.split('.')
        this.updateData.name = t.slice(0, t.length - 1).join('.').replace('v', '');
        this.updateData.file = file;

        // const fr = new FileReader()
        // fr.readAsDataURL(file)
        // fr.addEventListener('load', () => {
        //   this.updateData.name = file.name;
        //   this.updateData.file = fr.result;
        // })
      }

    },
    async uploadApk() {
      console.log('uploadApk... ');

      console.log(this.updateData);

      try {
        this.loading = true;
        const uploadResponse = await apiService.uploadNewApk(this.updateData);
        console.log('uploadResponse ->>>> ', uploadResponse);
        this.loading = false;
        this.success = uploadResponse.success;
        if (uploadResponse.success === false) {
          this.$store.commit('setSnackMessage', {
            message: `Помилка\n${uploadResponse.error}`,
            isError: true
          })
        }
      } catch (e) {
        this.$store.commit("updateProgressVal", 0);
        this.$store.commit('setSnackMessage', {
          message: `Помилка \n${e}`,
          isError: true
        })
        console.log('Error upload file: ', e);
        this.loading = false;
        this.success = false;
      }


    },
    closeModal() {
      this.reset();
      this.$refs.fileupload.reset();
      this.updApkDialog = false;

    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
  computed: {
    updApkDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
