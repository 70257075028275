<template>
  <v-row justify="center">
    <v-dialog
        v-model="changeBgDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >

      <v-card>
        <div v-if="loading" style="position: fixed; top: 17%; left: 35%; right: 35%; z-index: 100;">
          <v-progress-circular
              :rotate="-90"
              :size="500"
              :width="20"
              :value="$store.getters.getProgressVal"
              color="primary"
              :indeterminate="true"
          >
            <h2 v-if="isFireBaseRequest">Заміна заставки...</h2>
            <h1 v-else>{{ $store.getters.getProgressVal }}%</h1>
          </v-progress-circular>
        </div>
        <v-toolbar
            dark
            color="primary"
        >

          <v-toolbar-title>Зміна першого екрану кіоска</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                icon
                dark
                @click="changeBgDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row justify="space-around" class="pa-10">
          <v-col cols="3" offset="1">
            <v-card elevation="24" class="mx-auto ">
              <v-card-title>Змінити для:</v-card-title>
              <v-card-text>
                <v-radio-group
                    v-model="changeForKiosk"
                    row
                >
                  <v-radio v-if="firebaseToken"
                      :label="deviceId"
                      :value="deviceId"
                  ></v-radio>
                  <v-radio
                      label="Усіх"
                      value="all"
                  ></v-radio>
                </v-radio-group>
                <v-file-input
                    clearable
                    counter
                    show-size
                    ref="kioskMedia"
                    @change="onKioskMediaPicked"
                    @click:clear="mediaFile = null"
                ></v-file-input>
              </v-card-text>
              <v-col class="text-center">
                <v-btn class="ma-2" :disabled="!mediaFile || loading" @click="previewBgOnVirtual">Перегляд</v-btn>
                <v-btn class="ma-2" :disabled="!mediaFile || loading" @click="changeBg(false)">Змінити</v-btn>
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="1"></v-col>

          <v-divider
              vertical
          ></v-divider>

          <v-col cols="5" offset="1">
            <div class="text-center" style="overflow: hidden; height: 150vh;">
              <div :class="[isFrameScaled ? 'kiosk-frame-05' : 'kiosk-frame-03']" >
                <v-card elevation="24">
                  <div class="kiosk-frame">
                    <div class="frame-loader-container" v-if="iframeLoader.isVisible">
                      <v-progress-circular
                          :size="650"
                          :width="50"
                          color="red"
                          :indeterminate="true"
                      >
                        <h1 class="text-lg-h3" style="font-weight: bold;">{{iframeLoader.text}}</h1>
                      </v-progress-circular>
                    </div>
                    <iframe @load="onIframeLoaded" src="https://dev.royalcasino.ua/api/kiosk/virtual"
                            class="elevation-24 kiosk-frame"></iframe>
                  </div>

                </v-card>
              </div>
            </div>
            <div class="zoom-in-out-btn">
              <v-btn icon @click="isFrameScaled = !isFrameScaled">
                <v-icon size="35">
                  {{ `mdi-magnify-${isFrameScaled ? 'minus' : 'plus'}-outline` }}
                </v-icon>
              </v-btn>
            </div>
          </v-col>
          <!--          <v-col cols="6">-->
          <!--          </v-col>-->
        </v-row>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import apiService from "@/apiServices/apiService";

export default {
  name: "ChangeKioskBackground",
  data: () => ({
    mediaFile: null,
    changeForKiosk: null,
    isFrameScaled: false,
    isFireBaseRequest: false,
    loading: false,
    iframeLoader : {isVisible: true, text: 'Завантаження...'}
  }),
  props: {
    isDialogVisible: Boolean,
    deviceId: String,
    firebaseToken: String,
    isInvokeToChangeForAll: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    iframeChildMessageHandler(event){
      const msg = event.data;
      console.log('MSG from iFrame: ', msg)
      if (msg === 'loaded'){
        this.iframeLoader.isVisible = false
      }
    },
    async onIframeLoaded(){
      await this.previewBg()
    },
    async previewBgOnVirtual(){
      await this.changeBg(true);
    },
    async previewBg(isVirtual = false) {
      console.log('Send to iframe:')
      this.iframeLoader.text = 'Оновлення Заставки...'
      const iframeEl = document.querySelector("iframe");
      iframeEl.contentWindow.postMessage({"deviceId": isVirtual ? 'idK_virtual' : this.deviceId}, "*");
    },
    async changeBg(isVirtual = false) {
      console.log('Change bg is virtual = ', isVirtual)
      if (!this.mediaFile) {
        return false
      }
      this.loading = true;
      try {
        const updateMediaResponse = await apiService.uploadKioskBg(this.mediaFile, isVirtual ? 'idK_virtual' : (this.changeForKiosk === 'all' ? null : this.changeForKiosk));
        console.log('updateMediaResponse: ', updateMediaResponse)
        if(!isVirtual){
          await this.sendFirebaseActionMsg()
        }else {
          this.loading = false
        }
      } catch (e) {
        this.loading = false
        alert('Can send new BG')
        alert(e)
      }finally {
        if(!this.firebaseToken){
          await this.previewBg(true)
        }else{
          await this.previewBg(isVirtual)
        }

      }
    },

    async sendFirebaseActionMsg() {
      try {
        this.isFireBaseRequest = true
        const body = {action: 'update_bg'}
        if (this.changeForKiosk === 'all') {
          body.topic = 'royal_kiosks'
        } else {
          body.tokens = new Array(this.firebaseToken)
        }
        const fbMsgResponse = await apiService.sendFirebasePushMsg(body)
        console.log(fbMsgResponse)
        if (!fbMsgResponse['success']) {
          alert(`🔴 ERROR\n${fbMsgResponse['error']}`)
        }else {
          alert(`✅ Готово`)
        }
      } catch (e) {
        alert(`🔴 ERROR\n${e}`)
      } finally {
        this.isFireBaseRequest = false;
        this.loading = false
      }
    },

    onKioskMediaPicked(file) {
      if (file) {
        console.log(file)
        console.log(file.name)
        this.mediaFile = file;

      }
    }
  },
  computed: {
    changeBgDialog: {
      get() {
        return this.isDialogVisible
      },
      set(value) {
        this.$emit('close-changer', value)
      }
    }

  },
  mounted() {
    console.log('Change bg component is mounted')
    this.changeForKiosk = this.firebaseToken ? this.deviceId : 'all'
    this.isFireBaseRequest = false
    window.addEventListener("message", this.iframeChildMessageHandler)
  }
}
</script>


<style scoped>

.zoom-in-out-btn {

  position: absolute;
  top: 100px;
  right: 50px;
  transition: transform .2s;
}

.zoom-in-out-btn:hover {
  transform: scale(2);
}

.kiosk-frame {
  width: 1080px;
  height: 1920px;
}

.kiosk-frame-03 {

  transform: scale(0.3, 0.3);
  transform-origin: top left;
}

.kiosk-frame-05 {
  transform: scale(0.5, 0.5);
  transform-origin: top left;
}

.frame-loader-container {
  position: absolute;
  right: 0;
  left: 0;
  width: inherit;
  top: 30%;
}


</style>