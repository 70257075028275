<template>
  <v-dialog
      v-model="createVenueDialog"
      persistent
      max-width="600px"
  >
    <v-snackbar
        v-model="snackbar"
        color="warning"
        absolute
        top
        right
        elevation="24"
        centered
        text
        timeout="3500">
      Назва локаці відсутня
      <template v-slot:action="{ attrs }">
        <v-btn
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card>
      <v-progress-linear v-if="loading"
                         :active="loading"
                         :indeterminate="loading"
                         height="6px"
      ></v-progress-linear>
      <div v-if="loading" style="padding-top:20px; text-align: center">Очікуйте...

      </div>
      <v-card-title>
        <span class="text-overline mb-lg-7">Нова Локація: {{success ? 'Успішно створена' : ''}}</span>
      </v-card-title>
      <v-card-text>
        <v-form
            ref="form"
            lazy-validation
        >
          <v-col>
            <v-text-field
                :disabled="success"
                v-model="location.name"
                :counter="19"
                label="Назва локації"
                required
                append-icon="mdi-hospital-building"
            ></v-text-field>
          </v-col>


          <v-text-field
              v-model="location.comment"
              label="Коментар"
              :disabled="success"
          ></v-text-field>


          <v-btn
              :disabled="success"
              color="primary"
              class="mr-4"
              @click="createLocation"
          >
            Створити
          </v-btn>




        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :disabled="loading"
            color="blue darken-1"
            text
            @click="closeModal"
        >
          Закрити
        </v-btn>

      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import apiService from "@/apiServices/apiService";
export default {
name: "CreateNewVenue",
  comments:{apiService},
  props: {
    value: Boolean,
  },
  data: ()=>({
    snackbar: false,
    loading: false,
    success: false,
    location: {name: '', comment: ''},
  }),
  methods: {
  async createLocation(){
    console.log('create new location... ')

    if (this.location.name.length > 3) {
      this.success = true;
      try {
        this.loading = true;
        const requestResponse = await apiService.createLocation(this.location);
        console.log(requestResponse)
        this.loading = false;
        this.success = requestResponse.success;
        if(requestResponse.success){
          // alert(`✅ Локація успішно створена`)
          this.$store.commit('setSnackMessage', {
            message: `✅ Локація успішно створена`,
            isError: false
          })
        }else{
          this.$store.commit('setSnackMessage', {
            message: `Помилка\n${requestResponse.error}`,
            isError: true
          })
        }

      }catch (e) {
        this.$store.commit('setSnackMessage', {
          message: `Помилка\n${e}`,
          isError: true
        })
        this.loading = false;
        this.success = false;
      }

    } else {
      this.snackbar = true;
    }
  },
    closeModal() {
      this.reset();
      this.$store.commit("changeUpdLocationsList", !this.$store.getters.isNedUpdLocationsList);
      this.createVenueDialog = false;

    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
  computed: {
    createVenueDialog: {
      get(){
        return this.value
      },
      set(value){
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
